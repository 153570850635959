import React, { useState } from 'react';
import { useLoading } from '../../../AppContext';
import { ColumnMemberCategory, INIT_MEMBER_CATEGORY_DATA } from '../const/CategoryConst';
import { deleteMemberCategories, getMemberCategories, getMemberById } from '../services/CategoryServices';
import { toast } from 'react-toastify';
import TableCustom from '../../component/table-custom/TableCustom';
import { ConfirmDialog } from '../../component/ConfirmDialog';
import CategoryMemberDialogAdd from './CategoryMemberDialogAdd';
import { GeneralSearchParamsType } from '../../utils/ParamsUtils';
import InputSearch from '../../component/input-field/InputSearch';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import ButtonCustom from '../../component/button-custom';
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS } from '../../../Constant';
import { SearchBase, searchBaseDefaultValue } from "../../utils/InterfaceUtils";
import { useAuth } from "../../auth";
import { handleError, removeTenantNameFormUsername } from '../../utils/FunctionUtils';
import MemberImportDialogAdd from './MemberImportDialogAdd';


interface CategoryMemberTabProps {
    // Define the props for your component here
}

const CategoryMemberTab: React.FC<CategoryMemberTabProps> = () => {
    const { setPageLoading } = useLoading();
    const { lang } = useMultiLanguage();
    const { currentUser } = useAuth();
    const [data, setData] = useState<any[]>([]);
    const [showDialogAddNew, setShowDialogAddNew] = useState<boolean>(false);
    const [showDialogDelete, setShowDialogDelete] = useState<boolean>(false);
    const [dataCategory, setDataCategory] = useState<any>();
    const [keyword, setKeyword] = useState<string>("");
    const [totalElements, setTotalElements] = useState<number>(0);
    const [numberOfElements, setNumberOfElements] = useState<number>(0);
    const [searchObject, setSearchObject] = useState<SearchBase>({
        ...searchBaseDefaultValue,
        orgId: currentUser?.orgId,
    });
    const [showImportMember, setShowImportMember] = useState<boolean>(false);

    const handleShowDialogAddNew = () => {
        setDataCategory({
            ...INIT_MEMBER_CATEGORY_DATA,
            active: true,
            orgId: currentUser?.orgId || "",
        });
        setShowDialogAddNew(true);
    };

    const handleCloseDialog = () => {
        setShowDialogAddNew(false);
        setShowDialogDelete(false);
        setDataCategory({});
    };

    const handleEditCategory = async (row: any): Promise<void> => {
        try {
            setPageLoading(true);
            let res = await getMemberById(row?.id);
            let newData = res.data?.data;
            setDataCategory({
                ...newData,
                username: removeTenantNameFormUsername(newData?.username),
                password: "",
                confirmPassword: "",
            });
            setShowDialogAddNew(true);
        } catch (error) {
            console.error(error);
        } finally {
            setPageLoading(false);
        }
    };

    const handleDeleteCategory = (row: any) => {
        setDataCategory(row);
        setShowDialogDelete(true);
    };

    const handleConfirmDelete = async (): Promise<void> => {
        try {
            setPageLoading(true);
            await deleteMemberCategories(dataCategory.id);
            toast.success(lang("TOAST.DELETE.SUCCESS"));
            handleCloseDialog();
        } catch (error) {
            handleError(error);
        } finally {
            setPageLoading(false);
        }
    };

    const updateData = async (params: GeneralSearchParamsType): Promise<void> => {
        try {
            setPageLoading(true);
            const { data } = await getMemberCategories(params);
            let newData = data?.data?.content;
            let pageable = data?.data?.pageable;
            setData(newData);
            setTotalElements(pageable.totalElements);
            setNumberOfElements(pageable.pageNumber + 1);
        } catch (error) {
            handleError(error);
        } finally {
            setPageLoading(false);
        }
    };

    const handleChange = (e: any) => {
        setKeyword(e.target.value);
    };

    const handleSearch = () => {
        setSearchObject((preState) => ({
            ...preState,
            pageIndex: searchBaseDefaultValue.pageIndex,
            keyword
        }));
    };

    return (
        <div className="category pt-4 bg-white area-category-edit-page wrapper-content">
            <div className="spaces space-between align-center mb-10">
                <div className="d-flex">
                    <ButtonCustom
                        className="spaces button-primary py-4 fs-12 h-30"
                        onClick={handleShowDialogAddNew}
                        checkAuthority={{
                            permission: AUTHORITIES_PERMISSIONS.EMPLOYEE,
                            ability: AUTHORITIES_ABILITY.ACTION,
                        }}
                    >
                        Thêm nhân viên
                    </ButtonCustom>
                    
                    <ButtonCustom
                        className="spaces button-primary-outline py-4 fs-12 h-30"
                        onClick={() => { setShowImportMember(true) }}
                        checkAuthority={{
                            permission: AUTHORITIES_PERMISSIONS.EMPLOYEE,
                            ability: AUTHORITIES_ABILITY.ACTION,
                        }}
                    >
                        Import nhân viên
                    </ButtonCustom>
                </div>
                <div className='spaces min-w-400'>
                    <InputSearch className='spaces h-31' 
                        handleChange={handleChange} 
                        handleSearch={handleSearch} 
                        placeholder="Tìm kiếm theo mã, tên nhân viên" 
                        isEnter
                    />
                </div>
            </div>
            <TableCustom
                data={data}
                height={"calc(100vh - 285px)"}
                columns={ColumnMemberCategory({ handleEditCategory, handleDeleteCategory })}
                justFilter={true}
                updatePageData={updateData}
                noPagination={false}
                objectSearch={searchObject}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                headerClasses='text-center'
            />

            {showDialogAddNew && (
                <CategoryMemberDialogAdd
                    show={showDialogAddNew}
                    onClose={handleCloseDialog}
                    data={dataCategory}
                    updatePageData={handleSearch}
                    // updatePageData={() => updateData(searchObject)}
                />
            )}

            {showDialogDelete && (
                <ConfirmDialog
                    show={showDialogDelete}
                    title="Xóa danh mục"
                    message={`Bạn chắc chắn muốn xóa nhân viên ${dataCategory.fullName} ?`}
                    yes="Xác nhận"
                    cancel="Hủy"
                    onYesClick={handleConfirmDelete}
                    onCancelClick={handleCloseDialog}
                />
            )}
            {
                showImportMember && (
                    <MemberImportDialogAdd
                        show={showImportMember}
                        onClose={() => setShowImportMember(false)}
                        setData={setData}
                        dataUser={data}
                    />
                )
            }
        </div>
    );
};

export default CategoryMemberTab;