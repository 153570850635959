import { useFormik } from 'formik';
import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../../_metronic/helpers';
import { useLoading } from '../../../AppContext';
import { RESPONSE_STATUS_CODE } from '../../auth/core/_consts';
import { Col } from '../../component/Grid';
import { handleError } from '../../utils/FunctionUtils';
import { FORM_CRITERIA_BTN, FORM_CRITERIA_BTN_INITAL } from '../config/ConfigButton';
import { initialValues } from '../const/SetOfCriteriaConst';
import { ICriteria, IScoreScale, ITermArea } from '../models/SetOfCriteriaModels';
import {
    addCriteriaForArea,
    getAreaListChildrenByTermForm,
    getCriteriaForDepartment,
    updateCriteriaForArea
} from '../services/SetOfCriteriaServices';
import "../style.scss";
import GroupButton from './GroupButton';
import SetOfCriteriaContext from './SetOfCriteriaContext';
import SetOfCriteriaDepartmentForm from './SetOfCriteriaDepartmentForm';
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS, STATUS_CODE_5S } from '../../../Constant';
import ButtonCustom from '../../component/button-custom';
import SelectCustom from '../../component/select-custom';
import { getAllParentZoneCategories } from '../../category/services/CategoryServices';
import TreeView from './CustomTreeSetOfCriteria';
import { useAuth } from '../../auth';
import { BASIC_FORM_CRITERIA, BASIC_FORM_INFO_ENABLE, DATACRITERIAADDTAB } from '../config/ConfigField';
import useMultiLanguage from '../../../hook/useMultiLanguage';

interface SetOfCriteriaAddTabProps {
    empApprove: string | null;
}

const SetOfCriteriaAddTab: React.FC<SetOfCriteriaAddTabProps> = ({
    empApprove
}) => {
    const { setPageLoading } = useLoading();
    const { currentUser } = useAuth();
    let navigate = useNavigate();
    const { lang } = useMultiLanguage();

    const { 
        criteriaList, 
        setCriteriaList, 
        maxPoint,
        termFormInfo,
        stageCodeInfo
    } = useContext(SetOfCriteriaContext);

    const [departmentList, setDepartmentList] = useState<any>([]);
    const [scoreScaleList, setScoreScaleList] = useState<IScoreScale[]>([]);
    const [areaId, setAreaId] = useState<number | null>(null);
    const [termAreaId, setTermAreaId] = useState<number | null>(null);
    const [btnAbility, setBtnAbility] = useState<any>(FORM_CRITERIA_BTN_INITAL);
    const [areaRootCode, setAreaRootCode] = useState<string | null>(termFormInfo.areaRootCode || null);
    const [checkIsView, setCheckIsView] = useState<boolean>(true);
    const [elementAbility, setElementAbility] = useState(BASIC_FORM_INFO_ENABLE);
    const [errors, setErrors] = useState<any>(null)

    const [checkDisabledBtnPaste, setCheckDisabledBtnPaste] = useState<boolean>(localStorage.getItem(DATACRITERIAADDTAB) ? false: true);

    const termFormId = termFormInfo.id || null;    

    const termAreaDTO: ITermArea = {
        termId: termFormId,
        areaId: areaId,
        areaRootCode: areaRootCode
    };

    useEffect(() => {
        setElementAbility(
            BASIC_FORM_CRITERIA[stageCodeInfo as keyof typeof BASIC_FORM_CRITERIA] || BASIC_FORM_INFO_ENABLE
        );
        setBtnAbility(
            FORM_CRITERIA_BTN[stageCodeInfo as keyof typeof FORM_CRITERIA_BTN] || FORM_CRITERIA_BTN_INITAL
        )
        if (areaRootCode) {
            handleGetChildrenListTermArea(areaRootCode)
            setCheckIsView(false);
        }
    }, []);

    useEffect(() => {
        handleReturnTotalPoint();
    }, [criteriaList]);

    const handleFormSubmit = async () => {
        try {
            if (errors && checkErrors(errors)) {
                toast.warning("Vui lòng kiểm tra thông tin đầu vào!");
                return;
            };
            const criteriaData = {
                termAreaDTO: termAreaDTO,
                lstScoreScale: scoreScaleList,
                lstTerm: criteriaList.filter(
                    criteria => !criteria?.hasRowButton
                        && (!criteria?.shortNameParent ? criteria?.name :
                            (criteria?.name && criteria?.shortNameParent)))
                    .map((criteria, index) => ({
                        ...criteria,
                        sortWeight: ++index
                    })),
            };

            const { data } = termAreaId ?
                await updateCriteriaForArea(termAreaId, criteriaData)
                :
                await addCriteriaForArea(criteriaData);
            handleResponseSaveOrUpdate(data);
        } catch (error) {
            console.error("Error", error);
        }
    };

    const formikTerm = useFormik({
        initialValues: initialValues,
        onSubmit: handleFormSubmit,
    });

    const handleResponseSaveOrUpdate = async (data: any) => {
        if (data?.code === RESPONSE_STATUS_CODE.INVALID_VALUE) {
            if (Array.isArray(data?.data)) {
                return toast.error(data.data?.[0]?.message);
            }
        };

        if (data?.data?.termAreaDTO?.id) {
            const { id } = data.data.termAreaDTO;
            setTermAreaId(id);            
            setDepartmentList((prevList: any[]) => updateDepartmentList(prevList, id, areaId));
        }
        setCheckIsView(false);
    };

    const updateDepartmentList = (departmentList: any[], newAreaId: number, oldAreaId: any) => {
        return departmentList.map((department: { children: any[]; }) => ({
            ...department,
            children: department.children.map((child: { areaId: number; }) => {
                return child.areaId === oldAreaId ? { ...child, id: newAreaId } : child;
            })
        }));
    };

    const handleChangeDepartment = (termArea: ITermArea) => {
        setAreaId(termArea.areaId || null);
        setTermAreaId(termArea.id || null);
        setScoreScaleList([]);

        if (!termArea.id) {
            setCriteriaList([]);
            return;
        }
        getTermsByTermArea(termArea.id);
    };

    const getTermsByTermArea = async (areaId: number) => {
        try {
            setPageLoading(true)
            const { data } = await getCriteriaForDepartment(areaId, "false");
            const criteriaListTemp: SetStateAction<ICriteria[]> = [];
            data?.data?.lstTerm.forEach((criteriaInfo: any) => {
                const criteriaChildren = criteriaInfo?.children?.map((childrenItem: any) => ({
                    ...childrenItem, 
                    shortNameParent: criteriaInfo.shortName
                }))
                criteriaListTemp.push(criteriaInfo, ...criteriaChildren, {
                    shortName: `${(criteriaInfo?.children.length + 1) / 10 + parseInt(criteriaInfo?.shortName)}`,
                    shortNameParent: criteriaInfo?.shortName,
                    name: "",
                    hasRowButton: true
                })
            })
            setCriteriaList([...criteriaListTemp]);
            setScoreScaleList(data?.data?.lstScoreScale);
        } catch (error) {
            handleError(error)
        } finally {
            setPageLoading(false)
        }
    };

    const handleAddScoreScale = () => {
        setScoreScaleList([
            ...scoreScaleList,
            {
                scoreFrom: 0,
                scoreTo: 0,
                description: '',
                scoreEmpMark: 0,
                scoreEmpOnus: 0
            }
        ]);
    };

    const handleReturnTotalPoint = () => {
        const criteriaListTemp = criteriaList.filter((criteria: ICriteria) => criteria?.shortNameParent && !criteria?.hasRowButton);
        return criteriaListTemp.length * maxPoint;
    };

    const handleGetChildrenListTermArea = async (rootCode: any) => {
        try {
            setPageLoading(true);
            const { data } = await getAreaListChildrenByTermForm(termFormId, rootCode || null);
            setAreaRootCode(rootCode);
            const departmentListTemp = data?.data?.map((department: ITermArea) => {
                return {
                    ...department,
                    code: department.areaId,
                    label: department.nameArea,
                    name: department.nameArea,
                }
            })

            setDepartmentList([
                {
                    code: "0",
                    label: null,
                    children: departmentListTemp
                }
            ]);
        } catch (error) {
            handleError(error)
        } finally {
            setPageLoading(false);
        }
    };

    const checkErrors = (obj: Record<string, any>): boolean => {
        for (const value of Object.values(obj)) {
            if (value !== null) {
                return true;
            }
        }
        return false;
    };

    const checkErrorsCriteriaList = (listCriteria: ICriteria[]) => {
        const findItem = listCriteria.filter(criteria => !criteria?.hasRowButton).find(criteria => criteria?.shortNameParent);
        if (findItem) return false
        return true;
    };

    const checkErrorsScoreScaleList = (scoreScaleList: IScoreScale[]) => {
        let isCheck = true;
        scoreScaleList.map((scoreScale: IScoreScale, index: number) => {
            const scoreFrom = scoreScale.scoreFrom?.toString();
            const scoreTo = scoreScale.scoreTo?.toString();
            if (!scoreFrom || !scoreTo) {
                isCheck = false;
            } else {
                isCheck = Number(scoreScale.scoreFrom) < Number(scoreScale.scoreTo);
                !isCheck && setErrors({
                    [`scoreFrom-${index}`]: "Phải nhỏ hơn tổng điểm đến",
                    [`scoreTo-${index}`]: "Phải lớn hơn tổng điểm từ",
                });
            }
        });
        return isCheck;
    };

    const handleClearDataCriteriaAddTab = () => {
        localStorage.removeItem(DATACRITERIAADDTAB);
    };

    const handleCopyDataCriteriaAddTab = () => {
        try {
            const dataCriteriaAddTab = {
                criteriaList: criteriaList,
                scoreScaleList: scoreScaleList,
            };
            localStorage.setItem(DATACRITERIAADDTAB, JSON.stringify(dataCriteriaAddTab));
            setCheckDisabledBtnPaste(false);
            toast.success(lang("TOAST.COPY.SUCCESS"));
        } catch (error) {
            console.error("ERROR", error);
            toast.error(lang("TOAST.COPY.ERROR"));
        };
    };

    const handlePasteDataCriteriaAddTab = async() => {
        try {
           const dataCriteriaAddTab = localStorage.getItem(DATACRITERIAADDTAB) ? JSON.parse(localStorage.getItem(DATACRITERIAADDTAB) || "") : null;
           setCriteriaList(dataCriteriaAddTab?.criteriaList);
           setScoreScaleList(dataCriteriaAddTab?.scoreScaleList);
           await handleFormSubmit();
           toast.success(lang("TOAST.PASTE.SUCCESS"));
        } catch (error) {
            console.error("ERROR", error);
            toast.error(lang("TOAST.PASTE.ERROR"));
        };
    };

    return (
        <div>
            <div className="divider" />
            <form onSubmit={formikTerm.handleSubmit}>
                <Row className='spaces px-6'>
                    <Col xs={2} md={3} className='treelist-criteria-container'>
                        <div className="flex justify-content-between me-4 flex-wrap">
                            <div className='spaces flex flex-start flex-row color-primary align-center gap-5 mb-5'>
                                <KTSVG path="/media/icons/menu-fill.svg" className='color-primary btn-icon svg-icon-3' />
                                <span className='spaces fs-16 fw-bold pt-4'>Chọn Layout</span>
                            </div>
                            <SelectCustom
                                menuPlacement="auto"
                                service={getAllParentZoneCategories}
                                params={{ statusCode: STATUS_CODE_5S.HIEN_HANH, orgId: currentUser?.orgId }}
                                className='spaces min-w-150'
                                valueField={'code'}
                                value={areaRootCode}
                                displayField={'name'}
                                optionsResponse='content'
                                handleChange={(rootCode) => { handleGetChildrenListTermArea(rootCode) }}
                                isDisabled={checkIsView ? !Boolean(termFormId): true}
                            />
                        </div>
                        <div className='custom-treeview-criteria pt-4'>
                            <TreeView
                                data={departmentList}
                                isView={true}
                                isAlwayOpen={true}
                                defaultSelected={departmentList[0]?.children[0]}
                                handleChangeSelection={(termArea: ITermArea) => handleChangeDepartment(termArea)}
                            />
                        </div>
                    </Col>
                    <Col xs={10} md={9} className='spaces detail-criteria-container pl-20 pt-15 detail-criteria-container-scroll position-relative'>
                        {areaRootCode && !elementAbility?.btn_save_form_basic && (
                            <div className='flex flex-space-between save-btn-sticky'>
                                <div className='flex'>
                                    <ButtonCustom
                                        checkAuthority={{
                                            permission: AUTHORITIES_PERMISSIONS.FORM,
                                            ability: AUTHORITIES_ABILITY.ACTION
                                        }}
                                        onClick={handleCopyDataCriteriaAddTab}
                                        disabled={!Boolean(termFormId)}
                                    >
                                        Sao chép
                                    </ButtonCustom>
                                    <ButtonCustom
                                        checkAuthority={{
                                            permission: AUTHORITIES_PERMISSIONS.FORM,
                                            ability: AUTHORITIES_ABILITY.ACTION
                                        }}
                                        onClick={handlePasteDataCriteriaAddTab}
                                        disabled={!Boolean(termFormId) || checkDisabledBtnPaste}
                                    >
                                        Dán
                                    </ButtonCustom>
                                </div>
                            </div>
                        )}
                        <SetOfCriteriaDepartmentForm
                            totalPoint={handleReturnTotalPoint()}
                            scoreScaleList={scoreScaleList}
                            setScoreScaleList={setScoreScaleList}
                            handleAddScoreScale={handleAddScoreScale}
                            hasRootArea={Boolean(areaRootCode)}
                            setErrors={setErrors}
                            errors={errors}
                            handleSaveCriteria={formikTerm.handleSubmit}
                        />
                    </Col>
                </Row>
                <div className="divider" />
            </form>

            {termFormId && (
                <div className='flex justify-center mt-10 mb-4'>
                    <GroupButton
                        elementAbility={btnAbility}
                        termFormData={termFormInfo}
                        handleClose={() => {
                            handleClearDataCriteriaAddTab();
                            navigate('/set-of-criteria');
                        }}
                        departmentArea={departmentList[0]?.children}
                        ability={AUTHORITIES_ABILITY.ACTION}
                        empApprove={empApprove}
                        errors={errors}
                    />
                </div>
            )}
        </div>
    );
};

export default SetOfCriteriaAddTab;