import { toAbsoluteUrl } from "../../../_metronic/helpers";
import "./homepage.scss";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../../modules/auth";
import { hasAuthority, hasRole } from "../../modules/utils/FunctionUtils";
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS, MODULE, PERMISSIONS, ROLE } from "../../Constant";
import useMultiLanguage from "../../hook/useMultiLanguage";

interface LinkBtnProps {
  linkTo: string;
  iconPath: string;
  text: string;
  modulePermission: string;
  permission: string;
}

const countAuthorities = () => {
  const authorities = [
    hasAuthority(AUTHORITIES_PERMISSIONS.DASHBOARD, AUTHORITIES_ABILITY.VIEW),
    hasAuthority(AUTHORITIES_PERMISSIONS.APPROVE, AUTHORITIES_ABILITY.SEARCH),
    hasAuthority(AUTHORITIES_PERMISSIONS.PROGRAM, AUTHORITIES_ABILITY.SEARCH),
    hasAuthority(AUTHORITIES_PERMISSIONS.FORM, AUTHORITIES_ABILITY.SEARCH),
    hasAuthority(AUTHORITIES_PERMISSIONS.EVALUATE, AUTHORITIES_ABILITY.SEARCH),
    hasAuthority(AUTHORITIES_PERMISSIONS.FIX, AUTHORITIES_ABILITY.SEARCH),
    hasAuthority(AUTHORITIES_PERMISSIONS.AREA, AUTHORITIES_ABILITY.SEARCH),
    hasAuthority(AUTHORITIES_PERMISSIONS.EMPLOYEE, AUTHORITIES_ABILITY.SEARCH)
  ];

  const count = authorities.filter(authority => authority === true).length;
  return count;
}

const LinkButton = (props: LinkBtnProps) => {
  const { linkTo = "", iconPath, text, modulePermission, permission } = props;

  let colSize = 12;
  switch (countAuthorities()) {
    case 8:
      colSize = 3;
      break;
    case 7:
      colSize = 3;
      break;
    case 6:
      colSize = 4;
      break;
    case 5:
      colSize = 3;
      break;
    case 4:
      colSize = 6;
      break;
    case 3:
      colSize = 6;
      break;
    case 2:
      colSize = 6;
      break;
    case 1:
      colSize = 12;
      break;
    default:
      colSize = 12;
      break;
  }
  return (
    <>
      {/* khi có phân quyền sẽ check */}
      {hasAuthority(permission, modulePermission) && (
        
      <Col xs={12} sm={6} md={colSize} xl={colSize} className="flex flex-center">
        <Link to={linkTo}>
          <div className="link-button-container">
            <button
              type="button"
              className="button-link"
            >
              <div className="cirle-animation cirle-animation-1"></div>
              <div className="cirle-animation cirle-animation-2"></div>
              <Image src={toAbsoluteUrl(iconPath)} alt="image" />
            </button>
            <span className="button-text">{text}</span>
          </div>
        </Link>
      </Col>
      )}
    </>
  )
}

export function HomePage() {
  const { lang } = useMultiLanguage();
  const { logout, currentUser } = useAuth();
  document.title = `${lang('SOFTWARE')}`;

  return (
    <div className="main">
      <div className="header">
        <div className="logo">
          <Image src={toAbsoluteUrl("./media/logos/logo.png")} />
        </div>
        <div className="user">
          <div className="user-info">
            <span>{lang("GENERAL.HELLO")}</span>
            <h5>{ currentUser?.fullname }</h5>
          </div>
          <div className="user-avatar">
            <img src={toAbsoluteUrl("./media/avatars/300-1.jpg")} alt="avatar" />
          </div>
          <div className="user-logout">
            <button type="button" onClick={logout}>{lang("LOGOUT")}</button>
          </div>
        </div>
      </div>
      <div className="z-100 main-content">
        <div className="body-container">
          <Row>
            <Col xs={12}>
              <h2 className="title-software">{"PHẦN MỀM 5S"}</h2>
            </Col>
          </Row>
          <Row className="flex flex-start">
            <LinkButton
              linkTo={"/dashboard"}
              iconPath="/media/icons/homepage/profile.svg"
              text={"Dashboard"}
              permission={AUTHORITIES_PERMISSIONS.DASHBOARD}
              modulePermission={AUTHORITIES_ABILITY.VIEW}
            />
            <LinkButton
              linkTo={"/program"}
              iconPath="/media/icons/homepage/profile.svg"
              text={"Phê duyệt"}
              permission={AUTHORITIES_PERMISSIONS.APPROVE}
              modulePermission={AUTHORITIES_ABILITY.SEARCH}
            />
            <LinkButton
              linkTo={"/program"}
              iconPath="/media/icons/homepage/profile.svg"
              text={"Chương trình"}
              permission={AUTHORITIES_PERMISSIONS.PROGRAM}
              modulePermission={AUTHORITIES_ABILITY.SEARCH}
            />
            <LinkButton
              linkTo={"/set-of-criteria"}
              iconPath="/media/icons/homepage/profile.svg"
              text={"Biểu mẫu"}
              permission={AUTHORITIES_PERMISSIONS.FORM}
              modulePermission={AUTHORITIES_ABILITY.SEARCH}
            />
            <LinkButton
              linkTo={"/evaluate/incomming"}
              iconPath="/media/icons/homepage/profile.svg"
              text={"Đánh giá"}
              permission={AUTHORITIES_PERMISSIONS.EVALUATE}
              modulePermission={AUTHORITIES_ABILITY.SEARCH}
            />
            <LinkButton
              linkTo={"/repeair"}
              iconPath="/media/icons/homepage/profile.svg"
              text={"Khắc phục"}
              permission={AUTHORITIES_PERMISSIONS.FIX}
              modulePermission={AUTHORITIES_ABILITY.SEARCH}
            />
            <LinkButton
              linkTo={"/areas"}
              iconPath="/media/icons/homepage/profile.svg"
              text={"Khu vực"}
              permission={AUTHORITIES_PERMISSIONS.AREA}
              modulePermission={AUTHORITIES_ABILITY.SEARCH}
            />
            <LinkButton
              linkTo={"/employees"}
              iconPath="/media/icons/homepage/profile.svg"
              text={"Nhân viên"}
              permission={AUTHORITIES_PERMISSIONS.EMPLOYEE}
              modulePermission={AUTHORITIES_ABILITY.SEARCH}
            />
            <LinkButton
              linkTo={"/unrated-program-statistics"}
              iconPath="/media/icons/homepage/profile.svg"
              text={"Thống kê"}
              permission={AUTHORITIES_PERMISSIONS.STATISTICS}
              modulePermission={AUTHORITIES_ABILITY.VIEW}
            />
          </Row>
        </div>
      </div>
    </div>
  );
}
