import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useNotification } from '../../../../app/AppContext'
import { STATUS_READ_NOTI } from '../../../../app/modules/constant'
import { getDataNotifications } from '../../../../app/modules/services'
import HeaderNotificationsMenu, { INoti } from '../../../partials/layout/header-menus/HeaderNotificationsMenu'
import { useLayout } from '../../core'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { getAuth, useAuth } from '../../../../app/modules/auth'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import ScoreLogs from '../../../partials/layout/header-menus/ScoreLogs'
import { localStorageItem } from '../../../../app/modules/utils/LocalStorage'
import { KEY_LOCALSTORAGE } from '../../../../app/modules/auth/core/_consts'

const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const { config } = useLayout();
  const { currentUser } = useAuth();
  const auth = getAuth();
  const { listNotifications, setListNotifications } = useNotification();

  const countUnRead = listNotifications?.filter((item: INoti) => item?.status === STATUS_READ_NOTI.UN_READ).length;
  const [openScoreLogs, setOpenScoreLogs] = useState<boolean>(false);

  useEffect(() => {
    const controller = new AbortController();
    const getNotify = async() => {
      // await handleGetListNotifications();
      // fetchNotifications(controller);
    }
    getNotify()
    return () => {
      controller.abort();
    };
  }, []);

  const handleGetListNotifications = async () => {
    const res = await getDataNotifications({
      pageIndex: 1,
      pageSize: 10,
    })
    setListNotifications(res?.data?.data)
  };

  const fetchNotifications = async (controller: AbortController) => {
    await fetchEventSource(`${localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"]}/notifications/realtime`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${auth?.access_token}`,
        'Content-Type': 'text/event-stream',
        'Cache-Control': 'no-cache',
        'X-Accel-Buffering0': 'no',
      },
      signal: controller.signal,
      openWhenHidden: true,
      onmessage: (event) => {
        const newNotification = JSON.parse(event.data);
        if (newNotification.title) {
          setListNotifications((perState) => ([newNotification, ...perState]))
          addShakeEffect("ring-button")
        }
      },
      onclose() {
        console.log("Closed SSE");
      },
      onerror(err) {
        console.error('Error:', err);
      }
    })
  };

  const addShakeEffect = (iconId: string) => {
    const icon = document.getElementById(iconId);
    if (icon) {
      icon.classList.add('shake');
      setTimeout(() => {
        icon.classList.remove('shake');
      }, 1000);
    } else {
      console.log('Icon not found');
    }
  };

  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      <div className={clsx('app-navbar-item')}>
        {/* <div className="notification cursor-pointer p-4">
          <KTSVG path={'/media/icons/notification.svg'} className={`svg-icon-2`} />
        </div> */}
        <div>
          <div
            className="notification cursor-pointer p-4 mx-3"
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'

          >
            <button id='ring-button' type="button" className="btn p-0 position-relative">
              <KTSVG path={'/media/icons/notification.svg'} className={`flex mx-0 p-1`} />
              <span className="position-absolute top-0 translate-middle badge rounded-pill bg-danger">
                {countUnRead || 0}
              </span>
            </button>
          </div>
          <HeaderNotificationsMenu listNotifications={listNotifications} />
        </div>
        <div
          className={clsx('cursor-pointer', 'flex', 'flex-center', 'align-items-center')}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <div className='header-user-info color-white'>
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' width={24} height={24} className='avatar rounded-circle' />
          </div>
          <div className='header-user-info color-white'>
            <div className="name">{currentUser?.fullName || ''}</div>
          </div>
        </div>

        <div className='spaces bg-white px-10 py-3 rounded-md ml-15 cursor-pointer'
          onClick={() => setOpenScoreLogs(true)}
        >
          <div className="name">{currentUser?.score || 0} Điểm</div>
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
      {openScoreLogs && (
        <ScoreLogs 
          show={openScoreLogs}
          onClose={() => setOpenScoreLogs(false)}
        />
      )}
    </div>
  )
}

export { Navbar }

