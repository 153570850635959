import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { unstable_batchedUpdates } from "react-dom";
import "../styles/styles.scss";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InfoEvaluation from "./InfoEvaluation";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { useFormik } from "formik";
import { useLoading } from "../../../AppContext";
import { INITIAL_VALUE_EVALUATE_FORM } from "../const/EvaluateConst";
import {
  saveEvaluate,
  getEvaluateById,
  getProgramImplSchedule,
  getScoreScaleByTermAreaId,
  saveFixRemedy,
  updateAssignmentEvaluate,
  getProgramImplScheduleNeedRemedy,
  getProgramImplScheduleBeenRemedy,
} from "../EvaluateServices";
import { toast } from "react-toastify";
import { handleError, hasAuthority } from "../../utils/FunctionUtils";
import GroupButton from './GroupButton';
import { EVALUATE_BUTTON } from "../config/configButton";
import FloatingNotification from "../../component/FloatingNotification";
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS, PROGRESS_EVALUATE, STATUS_CODE_5S } from "../../../Constant";
import CustomIconButton from "../../component/CustomIconButton";
import ProgramAreaDialog from "../../program/component/ProgramAreaDialog";
import HistoryRequest from "../../component/History-request/HistoryRequest";
import { PopupAssignmentRequest } from "../../component/PopupAssignmentRequest";
import { StageSignature } from "../../constant";
import { StageSignature as STATUS_BUTTON } from "../../constant";

const FormEvaluation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useLoading();
  const { id } = useParams();
  const checkViewArea = !!hasAuthority(AUTHORITIES_PERMISSIONS.AREA, AUTHORITIES_ABILITY.SEARCH)
  let { proImplScheduleId, areaId, termFormId, termAreaId, isRepaired } = location?.state as {
    proImplScheduleId: number;
    areaId: number;
    termFormId: number;
    areaName: string;
    programId: number;
    isFromDashboard ?: boolean;
    termAreaId: number;
    isRepaired?: string;
  } || {}
  const [tableDataItem, setTableDataItem] = useState<any[]>([]);
  const [listTermAnswer, setListTermAnswer] = useState<any[]>([]);
  const [scoreScale, setScoreScale] = useState<any>(null);
  const [programImplResultList, setProgramImplResultList] = useState<any[]>([]);
  const [evaluatedList, setEvaluatedList] = useState<any[]>([]);
  const [lstButton, setLstButton] = useState<any[]>([]);
  const [openProgramArea, setOpenProgramArea] = useState<boolean>(false);
  const [openHistoryRequest, setOpenHistoryRequest] = useState<boolean>(false);
  const [openAssignmentRequestPopup, setOpenAssignmentRequestPopup] = useState<boolean>(false);

  useEffect(() => {    
    let _programImplResultList = tableDataItem.filter((item: any) => item.shortNameParent).map((item: any) => ({ ...item, proImplScheduleId: id }));
    let _evualatedList = _programImplResultList.filter((item: any) => typeof item?.termAnswerScore === 'number');
    setProgramImplResultList(_programImplResultList);
    setEvaluatedList(_evualatedList);
  }, [tableDataItem]);

  const updateTableData = async (proImplScheduleId: number, areaId: number | null, termFormId: number, termAreaId: number) => {
    try {
      setPageLoading(true);
      let data = null;
      if (isRepaired === "0") {
        data = await getProgramImplScheduleNeedRemedy({
          proImplScheduleId,
          areaId,
          termFormId,
          termAreaId
        });
      } else if (isRepaired === "1") {
        data = await getProgramImplScheduleBeenRemedy({
          proImplScheduleId,
          areaId,
          termFormId,
          termAreaId
        });
      } else {
        data = await getProgramImplSchedule({
          proImplScheduleId,
          areaId,
          termFormId,
          termAreaId
        });
      }
      const newData: any[] = data?.data.data?.map((item: any) => {
        return {
          ...item,
          hasSubRow: Boolean(item?.shortNameParent),
        };
      });
      setTableDataItem(newData);
      setListTermAnswer(newData[0]?.lstTermAnswer || []);
    } catch (error) {
      console.error(error);
    } finally {
      setPageLoading(false);
    }
  };
  
  useEffect(() => {
    handleGetEvaluateData();
    termAreaId && handleGetScoreScale(termAreaId);
    termAreaId && updateTableData(proImplScheduleId, areaId, termFormId, termAreaId);
  }, [id]);

  const validationSchema = Yup.object().shape({
    evaluateDate: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const handleGoBack = () => {
      navigate(-1)
  };

  const handleFormSubmit = async () => {
    try {
      setPageLoading(true);
      const submitData = {
        ...formikData.values,
        programImplResultList,
      };
      await saveEvaluate(submitData, Number(id));
      toast.success(lang("TOAST.EDIT.SUCCESS"));
    } catch (error) {
      handleError(error)
    } finally {
      setPageLoading(false);
    }
  };

  const handleFormSubmitFixed = async () => {
    try {
      setPageLoading(true);
      await saveFixRemedy(Number(id));
      toast.success(lang("TOAST.EDIT.SUCCESS"));
      navigate('/repeair');
    } catch (error) {
      handleError(error)
    } finally {
      setPageLoading(false);
    }
  };

  const formikData = useFormik({
    initialValues: INITIAL_VALUE_EVALUATE_FORM,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    unstable_batchedUpdates(() => {
      const { value, name } = e.target;
      formikData.setFieldValue(name, value);
    });
  }, []);

  const handleSelect = useCallback((name: string, value: any) => {
    unstable_batchedUpdates(() => {
      formikData.setFieldValue(name, value);
    });
  }, []);

  const handleGetScoreScale = async (id: number) => {
    try {
      setPageLoading(true);
      const resScoreScale = await getScoreScaleByTermAreaId(id);
      setScoreScale((resScoreScale.data.data))
    } catch (error) {
      handleError(error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleGetEvaluateData = async () => {
    try {
      const resEvaluate = await getEvaluateById(id);
      const data = {
        ...resEvaluate?.data?.data,
        stageCode: resEvaluate?.data?.data?.progress === PROGRESS_EVALUATE.SAP_TOI ? STATUS_BUTTON.UNKNOWN : resEvaluate.data.data?.stageCode
      }
      !termAreaId && await handleGetScoreScale(data?.termAreaId);
      !termAreaId && await updateTableData(
        Number(id),
        null,
        data?.termFormId,
        data?.termAreaId
      );
      formikData.setValues(data);
      setLstButton(EVALUATE_BUTTON[data?.stageCode]);
    } catch (error) {
      handleError(error);
    }
  };

  const handleRenderResultByScore = () => {
    let totalScore: number = formikData.values.totalTermAnswerScore || 0;

    if (scoreScale) {
      for (let i of Object.keys(scoreScale)) {
        if (totalScore <= Number(i)) {
          formikData.setFieldValue("resultText", scoreScale[i].description);
          formikData.setFieldValue("scoreEmpMark", scoreScale[i].scoreEmpMark);
          formikData.setFieldValue("scoreEmpOnus", scoreScale[i].scoreEmpOnus);
          break;
        }
      }
    }
  };

  const handleAssignment = async (data: any) => {
    try {
      setPageLoading(true);
      await updateAssignmentEvaluate(String(id), data);
      toast.success("Đã phân công.");
      navigate("/repeair")
      setPageLoading(false);
    } catch (error) {
      handleError(error);
      setPageLoading(false);
    }
  }

  useEffect(() => {
    handleRenderResultByScore();
  }, [formikData.values.totalTermAnswerScore, scoreScale]);

  return (
    <div className='wrapper-content'>
      <Row className="perform-evaluate">
        <Col xs={12} className="perform-evaluate-title info-box">
          <h6>THỰC HIỆN ĐÁNH GIÁ 5S</h6>
          <h6 className="perform-evaluate-title-id">{`#${id}`}</h6>
          {
            checkViewArea && <CustomIconButton
              tooltip="Xem thông tin khu vực"
              className="bi bi-geo-alt area-icon"
              onClick={() => setOpenProgramArea(true)}
            />
          }
          {
            !Boolean(formikData?.values?.userRemedy) && formikData?.values?.stageCode === StageSignature.EVALUATED_USER_ONUS && 
            <CustomIconButton
              tooltip="Phân công"
              className="fa-solid fa-user-tag assignment-icon"
              onClick={() => { setOpenAssignmentRequestPopup(true) }}
            />
          }
        </Col>
        <form onSubmit={formikData.handleSubmit}>
          <Col xs={12} className="spaces pt-24 min-h-600">
            <InfoEvaluation
              data={{
                handleSelect,
                handleChange,
                formikData,
                listTermAnswer,
                tableDataItem,
                setTableDataItem,
                totalTermAnswerScore: formikData.values.totalTermAnswerScore,
                setTotalTermAnswerScore: (total: number) => formikData.setFieldValue("totalTermAnswerScore", total),
                programImplResultList,
                setProgramImplResultList,
                evaluatedList,
                scoreScale,
              }}
            />
          </Col>
          <Col xs={12} className="justify-center spaces pt-24">
            <GroupButton
              scoreScale={{
                scoreEmpMark: formikData.values.scoreEmpMark,
                scoreEmpOnus: formikData.values.scoreEmpOnus
              }}
              isAssessFull={evaluatedList.length !== programImplResultList.length}
              lstButton={lstButton}
              handleFormSubmit={handleFormSubmit}
              handleFormSubmitFixed={handleFormSubmitFixed}
              handleClose={handleGoBack}
              dataForm={{
                ...formikData.values,
                programImplResultList
              }}
            />
          </Col>
        </form>
      </Row>

      {(formikData.values?.statusCode === STATUS_CODE_5S.YEU_CAU_SUA_DOI && formikData.values?.textRefuse) &&
        <FloatingNotification content={formikData.values?.textRefuse} />
      }

      {openProgramArea &&
        <ProgramAreaDialog
          show={openProgramArea}
          onClose={() => setOpenProgramArea(false)}
          areaDetail={{  areaId: formikData.values?.areaId ? formikData.values.areaId : '' }}
        />
      }

      {openHistoryRequest &&
        <HistoryRequest
          handleClose={() => setOpenHistoryRequest(false)}
          params={{ programImplScheduleId: id ? id.toString() : '' }}
        />
      }

      {openAssignmentRequestPopup &&
        <PopupAssignmentRequest
          show={openAssignmentRequestPopup}
          onSubmitForm={(data) => {
            handleAssignment(data)
          }}
          onCancelClick={() => setOpenAssignmentRequestPopup(false)}
          areaId={formikData.values?.areaId ? formikData.values.areaId : ''}
        />
      }
    </div>
  );
};

export default FormEvaluation;
