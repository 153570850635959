export const ConstantList = {
  ENTER_KEY: "Enter",
};
export enum SELECT_OPTION {
  SELECT_ACTION = "select-option",
  CLEAR_ACTION = "clear",
  REMOVE_ACTION = "remove-value",
}

export const PERMISSIONS = {
  AUTHORITY: "AUTHORITY",
  ROLE: "ROLE",
  USER: "USER",
  EVALUATE: "EVALUATE",
  TONG_QUAN:"TONG_QUAN",
  CHAM_CONG: "CHAM_CONG",
  ORGANIZATION: "ORGANIZATION",
  THUYEN_CHUYEN: "THUYEN_CHUYEN",
  EMPLOYEE: "EMPLOYEE",
  HOP_DONG: "HOP_DONG",
  NGHI_VIEC: "NGHI_VIEC",
  KHEN_THUONG: "KHEN_THUONG",
  BO_NHIEM: "BO_NHIEM",
  MIEN_NHIEM: "MIEN_NHIEM",
  PHUC_LOI: "PHUC_LOI",
  SU_CO: "SU_CO",
  DE_XUAT: "DE_XUAT",
  BAO_HIEM_Y_TE: "BAO_HIEM_Y_TE",
  MANAGEMENT_UNIT: "MANAGEMENT_UNIT",
  TAM_UNG: "TAM_UNG",
  THANH_TOAN: "THANH_TOAN",
  DANH_MUC: "DANH_MUC", 
  //pending authorities
  DONG_PHUC: "DONG_PHUC",
  QUY_HOACH: "QUY_HOACH",
  BAO_CAO: "BAO_CAO",
  TUYEN_DUNG: "TUYEN_DUNG",
  DAO_TAO: "DAO_TAO",
  PHONG_HOP: "PHONG_HOP", 
  CONG_VIEC: "CONG_VIEC",
  TAI_LIEU: "TAI_LIEU",
  PROJECT: "PROJECT",
  REPORT : "REPORT",
  MODULE: "MODULE",
  LUONG: "LUONG"
}

export const PERMISSION_ABILITY = {
  VIEW: "VIEW",
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  UPLOAD: "UPLOAD",
  EXPORT: "EXPORT"
}

export const MODULE = {
  HO_SO: "HO_SO",
  TUYEN_DUNG: "TUYEN_DUNG",
  CHAM_CONG: "CHAM_CONG",
  TAI_LIEU: "TAI_LIEU",
  DAO_TAO: "DAO_TAO",
  PHONG_HOP: "PHONG_HOP",
  CONG_VIEC: "CONG_VIEC",
  HE_THONG: "HE_THONG"
}

export const ROLE = {
  USER: "ROLE_USER",
}

export const STATUS_CODE_5S = {
  KHOI_TAO: "001",
  CHO_PHE_DUYET: "002",
  DA_PHE_DUYET: "003",
  YEU_CAU_SUA_DOI: "004",
  DANG_SUA_DOI: "005",
  CHUA_DANH_GIA: "006",
  DA_DANH_GIA: "007",
  DANG_SOAN_THAO: "008",
  HIEN_HANH: "009",
  DANG_DANH_GIA: "010",
  KET_THUC: "012",
  KHAC_PHUC: "015",
  DA_KHAC_PHUC: "016",
  CHO_TRIEN_KHAI: "017",
  CHO_PHAN_CONG: "018",
};

export const PROGRESS_EVALUATE = {
  DA_TROI_QUA: 1,
  HIEN_NAY: 2,
  SAP_TOI: 3
};

export const ELEMENT_ABILITY = {
    ACTION_EDIT: true,
    ACTION_APPROVE: true,

}

export const USER_CREATE = {
    ...ELEMENT_ABILITY,
    ACTION_EDIT: true,
    ACTION_APPROVE: false,
    
}
export const USER_APPROVE = {
    ...ELEMENT_ABILITY,
    ACTION_EDIT: false,
    ACTION_APPROVE: true,
}

export const STATUS_ELEMENT = {
    HIDDEN: "HIDDEN",
    DISPLAY: "DISPLAY"
}

export const CHECK_APPROVE = {
    APPROVE: "APPROVE",
    APPROVED: "APPROVED",
}
export const EVALUATE_TAB = {
  NOW: "NOW",
  UP_COMMING: "UP_COMMING",
  REVIEW: "REVIEW",
}

export const BTN_SAVE = {
    text: "Lưu",
    name: "btnSave",
    className: "button-primary spaces h-30",
    action:"handleSave",
}

export const BTN_SAVE_APPROVE = {
  text: "Lưu",
  name: "btnSaveApprove",
  className: "button-primary spaces h-30",
  action:"handleSaveApprove",
}

export const BTN_CANCEL = {
    text: "Hủy",
    name: "btnCancel",
    className: "button-primary-outline spaces h-30",
    action:"handleCancel",
}

export const BTN_SEND_REQUEST = {
    text: "Phê duyệt",
    name: "btnSendRequest",
    className: "button-primary spaces h-30",
    action:"handleSendRequest",
}

export const BTN_REQUEST_EDIT = {
    text: "Yêu cầu chỉnh sửa",
    name: "btnRequestEdit",
    className: "button-primary spaces h-30",
    action:"handleSendRequestEdit",
}

export const BTN_APPROVE = {
  text: "Phê duyệt",
  name: "btnApprove",
  className: "button-primary spaces h-30",
  action: "handleApprove",
}

export const BTN_ASSIGNED = {
  text: "Phân công",
  name: "btnAssigned",
  className: "button-primary spaces h-30",
  action: "handleAssigned",
}

export const BTN_DEPLOY = {
  text: "Triển khai",
  name: "btnDeploy",
  className: "button-primary spaces h-30",
  action: "handleDeploy",
}

export const BTN_FINISH = {
  text: "Kết thúc",
  name: "btnFinish",
  className: "button-primary spaces h-30",
  action: "handleFinish",
}

export const BTN_COMPLETE = {
  text: "Hoàn thành",
  name: "btnComplete",
  className: "button-primary spaces h-30",
  action: "handleComplete",
}

export const BTN_FIX = {
  text: "Khắc phục",
  name: "btnFix",
  className: "button-primary spaces h-30",
  action: "handleFix",
}

export const BTN_SAVE_FIX = {
  text: "Lưu",
  name: "btnFix",
  className: "button-primary spaces h-30",
  action: "handleSaveFix",
}

export const BTN_CLOSE = {
    text: "Đóng",
    name: "btnClose",
    className: "button-primary-outline spaces h-30",
    action:"handleClose",
}

export const BTN_EDIT = {
  text: "Sửa",
  name: "btnEdit",
  className: "button-primary spaces h-30",
  action: "handleEdit",
}

export const BTN_DELETE = {
  text: "Xóa",
  name: "btnDelete",
  className: "button-primary-outline spaces h-30",
  action: "handleDelete",
}

export const AUTHORITIES_PERMISSIONS = {
  AREA: "AREA",
  PROGRAM: "PROGRAM",
  EVALUATE: "EVALUATE",
  FORM: "FORM",
  APPROVE: "APPROVE",
  FIX: "FIX",
  EMPLOYEE: "EMPLOYEE",
  DASHBOARD: "DASHBOARD",
  STATISTICS: "STATISTICS",
}

export const AUTHORITIES_ABILITY = {
  ACTION: "ACTION",
  SEARCH: "SEARCH",
  REQUEST: "REQUEST",
  APPROVE: "APPROVE",
  ASSIGNMENT: "ASSIGNMENT",
  EMPLOYEES_BY_REGION: "EMPLOYEES_BY_REGION",
  VIEW: "VIEW",
  LEADER: "LEADER",
  SECRETARY: "SECRETARY",
  CONFIG: "CONFIG",
}