import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { ConfirmDialog } from "../../component/ConfirmDialog";
import TextValidator from "../../component/input-field/TextValidator";
import useStateRef from "../../utils/useStateRef";
import { INIT_CRITERIA_DATA } from "../const/SetOfCriteriaConst";
import { ICriteria } from "../models/SetOfCriteriaModels";
import SetOfCriteriaContext from "./SetOfCriteriaContext";
type TProps = {
    hasRootArea: boolean;
    handleSaveCriteria: () => void

}
const CriteriaTableV2 = ({
    hasRootArea,
    handleSaveCriteria
}: TProps) => {
    const {
        criteriaList,
        setCriteriaList,
        formTableAbility
    } = useContext(SetOfCriteriaContext);

    const criteriaListRef = useRef<ICriteria[]>(criteriaList);
    const [shouldOpenConfirmDeleteDialog, setShouldOpenConfirmDeleteDialog] = useState<boolean>(false);
    const [, setHasDeleteCategory, hasDeleteCategoryRef] = useStateRef<boolean>(false);
    const [, setShortNameCriteria, shortNameCriteriaRef] = useStateRef<string>("1");
    const [criteriaState, setCriteriaState] = useStateRef<ICriteria>(INIT_CRITERIA_DATA);
    const focusedRowRef = useRef<any>(null);
    const lastValueRef = useRef<any>(null);
    const rowRefs = useRef<any>({});

    const handleFocus = (index: any, value: string | undefined | null) => {
        focusedRowRef.current = index;
        lastValueRef.current = value;
    };
    useEffect(() => {
        criteriaListRef.current = criteriaList;
        if (criteriaList?.length === 0) {
            setShortNameCriteria("1");
        };
    }, [criteriaList])

    const handleBlurInput = (criteria: ICriteria, index: number, name: string) => {
        focusedRowRef.current = null;
        const isNameField = name === "name";
        const isDescriptionField = name === "description";
        const hasParent = Boolean(criteria.shortNameParent);

        if ((!criteria.name?.trim() || (!criteria.description?.trim() && hasParent)) && lastValueRef.current) {
            formik.setFieldValue(`data[${index}].${name}`, lastValueRef.current);
            return;
        }
        if ((isNameField && criteria.name?.trim() === lastValueRef.current.trim()) ||
            (isDescriptionField && criteria.description?.trim() === lastValueRef.current.trim())) {
            return;
        }


        handleSaveCriteria();
    };


    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (focusedRowRef.current !== null) {
                const currentRowRef = rowRefs.current[focusedRowRef.current];

                if (currentRowRef && !currentRowRef.contains(event.target)) {
                    const currentRowData = criteriaListRef.current.find((item) => item.shortName === focusedRowRef.current);
                    if (!currentRowData?.name && (!currentRowData?.shortNameParent || !currentRowData?.description) && (!lastValueRef.current)) {
                        setHasDeleteCategory(!currentRowData?.shortNameParent);
                        handleDeleteCriteria(focusedRowRef.current, false);
                    }
                    focusedRowRef.current = (null);
                    setCriteriaState(INIT_CRITERIA_DATA);
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [focusedRowRef]);


    const handleAddCriteria = (criteria: ICriteria) => {
        setCriteriaState(criteria);
        let isFoundIndex = false;
        const criteriaListTemp: ICriteria[] = [];
        const hasChildInParent = criteriaListRef.current.some(
            item => item.shortNameParent === criteria.shortNameParent && !item.hasRowButton
        );

        criteriaListRef.current?.forEach((item:ICriteria) => {
            const isSameParent = criteria.shortNameParent === item.shortNameParent;
            const isSameShortName = item.shortName === criteria.shortName;
            const shouldAddAfterRowButton = item.hasRowButton && isSameParent && (!hasChildInParent || isSameShortName);

            if (shouldAddAfterRowButton) {
                criteriaListTemp.push(
                    criteria,
                    { ...item, shortName: Number(+criteria.shortName + 0.1).toFixed(1) }
                );
                return;
            }

            if (isSameShortName && isSameParent) {
                isFoundIndex = true;
                criteriaListTemp.push(
                    criteria,
                    { ...item, shortName: Number(+item.shortName + 0.1).toFixed(1) }
                );
                return;
            }

            if (isFoundIndex) {
                criteriaListTemp.push({
                    ...item,
                    shortName: Number(+item.shortName + 0.1).toFixed(1)
                });
                return;
            }

            criteriaListTemp.push(item);
        });
        setCriteriaList(criteriaListTemp);
    };

    const handleDeleteCriteria = (criteriaId: string, inModal?: boolean) => {
        const criteriaListTemp = criteriaListRef.current.filter(criteriaItem => {
            return hasDeleteCategoryRef.current
                ? criteriaItem?.shortNameParent
                    ? criteriaItem?.shortNameParent !== criteriaId
                    : criteriaItem?.shortName !== criteriaId
                : criteriaItem?.shortName !== criteriaId;
        });

        let shortNameParentTemp = 0;
        let shortNameTemp = 0;
        const criteriaListAfterDelete = criteriaListTemp.map((criteriaItem) => {
            if (criteriaItem?.shortNameParent) {
                ++shortNameTemp;
                return {
                    ...criteriaItem,
                    shortNameParent: `${shortNameParentTemp}`,
                    shortName: `${shortNameParentTemp}.${shortNameTemp}`,
                }
            } else {
                ++shortNameParentTemp;
                shortNameTemp = 0;
                return {
                    ...criteriaItem,
                    shortName: `${shortNameParentTemp}`,
                }
            }
        })
        criteriaListRef.current = criteriaListAfterDelete;
        setCriteriaList(criteriaListAfterDelete);
        setCriteriaState(INIT_CRITERIA_DATA);
        inModal && setShouldOpenConfirmDeleteDialog(false);
        inModal && handleSaveCriteria();
    };

    const handleAddCriteriaCategory = () => {
        const findIndex = criteriaListRef.current.findIndex(criteria => criteria?.shortNameParent === (parseInt(shortNameCriteriaRef.current) - 1).toString() && criteria.hasRowButton);
        let listCriterial: ICriteria[] = [...criteriaListRef.current];
        const listSliceFirst = listCriterial.slice(0, findIndex + 1);
        const listSliceLast = listCriterial.slice(findIndex + 1);
        const newListSliceLast = autoIncrementShortName(listSliceLast);

        const newListCriterial = [
            ...listSliceFirst,
            {
                shortName: shortNameCriteriaRef.current,
                name: "",
                description: null,
                shortNameParent: null,
            },
            {
                shortName: "1",
                shortNameParent: shortNameCriteriaRef.current,
                name: "",
                hasRowButton: true
            },
            ...newListSliceLast,
        ]
        setCriteriaState({
            ...INIT_CRITERIA_DATA,
            shortName: `${shortNameCriteriaRef.current}`,
        })

        setCriteriaList(newListCriterial);
    };

    const renderRowButton = (isLastRow: boolean, criteria: ICriteria) => {
        return (
            <>
                <tr className="spaces h-32 fs-14 color-blue-600 border-y-dashed cursor-pointer">
                    <td
                        colSpan={12}
                        className="text-center"
                        onClick={() => {
                            handleClickBtnAddCategory(isLastRow, criteria?.shortNameParent);
                        }}
                    >
                        + Thêm danh mục
                    </td>
                </tr>
                <tr className="spaces h-1"></tr>
            </>
        )
    };

    const handleClickBtnAddCategory = (isLastRow: boolean, shortNameParent: any) => {
        let newShortNameCriteria: string | null = "1";
        if (criteriaList.length) {
            if (isLastRow) {
                newShortNameCriteria = (criteriaList.filter(criteria => !criteria?.shortNameParent).length + 1).toString();
            } else {
                const index = criteriaList.filter(criteria => !criteria?.shortNameParent).findIndex(criteria => criteria?.shortName === shortNameParent)
                newShortNameCriteria = (Number(index) + 2).toString();
            };

        };

        setShortNameCriteria(newShortNameCriteria);
        handleAddCriteriaCategory();
    };

    function autoIncrementShortName(list: ICriteria[]) {
        return list.map(item => ({
            ...item,
            shortName: (parseFloat(item.shortName) + 1).toString(),
            shortNameParent: item.shortNameParent ? (parseInt(item.shortNameParent) + 1).toString() : null,
        }));
    };
    const itemDragging = useRef<ICriteria>();
    const handleDragStart = (e: any, criteria: ICriteria) => {
        itemDragging.current = criteria;
    }
    
    const onDrop = (e: any, criteria: ICriteria) => {
        const { name, description, shortName, shortNameParent } = itemDragging.current || {};
        if(criteria.shortName === itemDragging.current?.shortName) return;
        setHasDeleteCategory(!shortNameParent);
        handleDeleteCriteria(shortName as string, true);
    
        handleAddCriteria({
            name: name as string,
            description,
            shortNameParent: criteria?.shortNameParent || criteria?.shortName,
            shortName: criteria?.shortNameParent 
                ? criteria.shortName 
                : (parseFloat(criteria?.shortName) + 0.1).toString()
        });
    };
    


    const renderRowTable = (criteria: ICriteria, index: number) => {
        const currentShortName = criteria.shortName;
        const [shortNameParent] = currentShortName?.split('.') || [];
        return (
            <>
                <tr onDoubleClick={()=>setCriteriaState(criteria)} draggable={!!criteria.shortNameParent}
                    key={`${criteria.shortName}`}
                    onDrop={(e) => onDrop(e, criteria)}
                    onDragStart={(e) => handleDragStart(e, criteria)}
                    onDragOver={(e) => e.preventDefault()}
                    ref={(el) => (rowRefs.current[criteria.shortName] = el)}
                    className={`cursor-pointer spaces h-40 max-h-40 spaces fs-14 border-top ${!criteria?.shortNameParent && "bg-gray-100"}`}>
                    <td className={`text-center ${!criteria?.shortNameParent && "fw-bolder spaces fs-17 "}`}>{criteria?.shortName}</td>
                    <td colSpan={(!criteria.shortNameParent) ? 2 : 1} className={`text-start p-0 ${!criteria?.shortNameParent && "fw-bolder spaces fs-16"}`}>
                        {
                            criteria?.shortName === criteriaState?.shortName ?
                                (<TextValidator
                                    className="flex-row min-w-80"
                                    readOnly={false}
                                    isRequired
                                    autoFocus
                                    placeholder={`Nhập ${!criteria?.shortNameParent ? "danh mục" : "tiêu chí"}`}
                                    onKeyDown={(event: React.KeyboardEvent) => handleKeyPress(event, criteria)}
                                    onBlur={() => { handleBlurInput(criteria, index, "name") }}
                                    onFocus={() => handleFocus(criteria?.shortName, criteria?.name)}
                                    name={`data[${index}].name`}
                                    value={formik.values.data[index]?.name}
                                    type="text"
                                    onChange={formik.handleChange}

                                />) : criteria?.name
                        }
                    </td>
                    {
                        (criteria?.shortNameParent) && (
                            <td className="text-start">
                                {
                                    criteria?.shortName === criteriaState?.shortName ?
                                        (<TextValidator
                                            className="flex-row min-w-80"
                                            readOnly={false}
                                            onBlur={() => { handleBlurInput(criteria, index, "description") }}
                                            isRequired
                                            type="text"
                                            placeholder="Nhập mô tả"
                                            onKeyUp={(event: React.KeyboardEvent) => handleKeyPress(event, criteria)}
                                            onFocus={() => handleFocus(criteria?.shortName, criteria?.description)}
                                            onChange={formik.handleChange}
                                            name={`data[${index}].description`}
                                            value={formik.values.data[index]?.description}
                                        />) : criteria?.description
                                }
                            </td>
                        )
                    }
                    {(!formTableAbility.table_criteria && (!criteria.shortNameParent || criteria.name || criteria.description)) && (
                        <td className="d-flex spaces justify-content-center align-items-center h-46 gap-4 p-4">
                            <div className="spaces w-40">
                                <i className="cursor-pointer bi bi-plus-square fs-3 text-primary px-4 fw-bold"
                                    onClick={() => {
                                        handleAddCriteria({
                                            ...INIT_CRITERIA_DATA,
                                            shortName: `${Number(+criteria.shortName + 0.1).toFixed(1)}`,
                                            shortNameParent: shortNameParent
                                        })
                                    }}
                                />
                            </div>
                            <i className="cursor-pointer bi bi-pencil-fill fs-4 text-primary px-4 fw-bold"
                                onClick={() => {
                                    setCriteriaState(criteria);
                                }}
                            />
                            <i className="cursor-pointer bi bi-trash fs-4 text-danger px-4 fw-bold"
                                onClick={() => {
                                    setCriteriaState(criteria);
                                    setHasDeleteCategory(Boolean(!criteria?.shortNameParent))
                                    setShouldOpenConfirmDeleteDialog(true);
                                }}
                            />
                        </td>
                    )}
                </tr>

            </>
        )
    };

    const renderAddNewCategory = () => {
        return (
            <tr className="spaces h-32 fs-14 color-blue-600 border-y-dashed cursor-pointer bg-gray-100 ">
                <td
                    colSpan={4}
                    className="text-center"
                    onClick={() => {
                        handleAddCriteriaCategory();
                    }}
                >
                    + Thêm danh mục
                </td>
            </tr>
        )
    };
    const validationSchema = Yup.object({});

    const formik = useFormik({
        initialValues: { data: criteriaList },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => { },
    });

    useEffect(() => {
        setCriteriaList(formik.values.data);
    }, [formik.values.data])

    const handleKeyPress = (event: React.KeyboardEvent, criteria: ICriteria) => {
        const currentShortName = criteria.shortName;
        const [shortNameParent] = currentShortName?.split('.') || [];
        if (event.key === "Enter") {
            if (criteria.name && (!criteria.shortNameParent || criteria.description)) {
                handleAddCriteria({
                    ...INIT_CRITERIA_DATA,
                    shortName: `${Number(+criteria.shortName + 0.1).toFixed(1)}`,
                    shortNameParent: shortNameParent
                });
            } else {
                event.preventDefault();
            }
        }
    };


    return (

        <div className="table-responsive customs-collapse-row m-0 spaces fs-14 mt-10 ">
            <form onSubmit={formik.handleSubmit}>
                <table className="dataTable table border w-100">
                    <thead className="position-sticky top-0 z-index-1">
                        <tr className="text-header-table fw-bolder gs-0">
                            <th className="p-table text-center bg-header-table spaces min-w-50">STT</th>
                            <th className="p-table text-start bg-header-table spaces min-w-400">Tiêu chí</th>
                            <th className="p-table text-start bg-header-table spaces min-w-400">Mô tả</th>
                            {!formTableAbility.table_criteria && <th className="p-table text-center bg-header-table spaces min-w-120">Thao tác</th>}
                        </tr>
                    </thead>
                    <tbody>

                        {Boolean(criteriaList.length) ? criteriaList.map((criteria, index) => {
                            const isLastRow = criteriaList.length === index + 1;
                            if (criteria?.hasRowButton && !formTableAbility.table_criteria) {
                                return renderRowButton(isLastRow, criteria)
                            }
                            if (!criteria?.hasRowButton) {
                                return renderRowTable(criteria, index)
                            }
                        })
                            :
                            <>
                                {(hasRootArea && !formTableAbility.table_criteria) ? (
                                    renderAddNewCategory()
                                ) : (
                                    <tr>
                                        <td colSpan={4} className="text-center">Không có bản ghi nào</td>
                                    </tr>
                                )}
                            </>
                        }

                        <tr className="spaces h-1 border-bottom"></tr>
                    </tbody>
                </table>
            </form>
            {shouldOpenConfirmDeleteDialog && (
                <ConfirmDialog
                    show={shouldOpenConfirmDeleteDialog}
                    onYesClick={() => handleDeleteCriteria(criteriaState?.shortName, true)}
                    onCancelClick={() => {
                        setShouldOpenConfirmDeleteDialog(false)
                        setCriteriaState(null as any);
                    }}
                    yes="Có"
                    cancel='Hủy'
                />
            )}            
        </div>
    )
}

export default CriteriaTableV2;