import TextValidator from '../../component/input-field/TextValidator';
import { Form } from 'react-bootstrap';
import Autocomplete from '../../component/input-field/Autocomplete';
import { EXPLAIN_LIST } from '../const/SetOfCriteriaConst';
import { IScoreScale } from '../models/SetOfCriteriaModels';
import GroupTableButton from '../../component/group-table-button/GroupTableButton';
import { getConfigTableButton } from '../config/ConfigTableAction';

export const SetOfCriteriaColumns = (props: any) => {
const { handleEdit, handleView, handleDelete } = props;
  return [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        minWidth: "40px",
        textAlign: "center"
      },
      render: (row: any, index: number, numericalOrder: number) => (
        <span>{numericalOrder}</span>
      )
    },
    {
      name: "Thao tác",
      field: "action",
      headerStyle: {
        width: "100px",
        textAlign: "center"
      },
      render: (row: any) => {
        let CONFIG_BTN = getConfigTableButton(row.userCreate);
        return (
          <GroupTableButton
            elementAbility={CONFIG_BTN[row.status]}
            handleView={() => handleView(row)}
            handleEdit={() => handleEdit(row)}
            handleDelete={()=>handleDelete(row)}
          />
        );
      }
    },
    {
      name: "Mã biểu mẫu",
      field: "code",
      headerStyle: {
        minWidth: "120px",
        textAlign: "center"
      },
    },
    {
      name: "Tên biểu mẫu",
      field: "name",
      headerStyle: {
        minWidth: "220px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: "Ngày tạo",
      field: "issuedDate",
      headerStyle: {
        minWidth: "80px",
        textAlign: "center"
      },
    },
    {
      name: "Mô tả",
      field: "note",
      headerStyle: {
        minWidth: "220px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: "Trạng thái",
      field: "statusName",
      headerStyle: {
        minWidth: "120px",
        textAlign: "center"
      },
    },
  ];
};
export const SetOfCriteriaDescColumns = (props: any) => {
  return [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        minWidth: "40px"
      },
      render: (row: any, index: number, numericalOrder: number) => (
        <span>{numericalOrder}</span>
      )
    },
    {
      name: "Chỉ tiêu",
      field: "name",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: "Mô tả",
      field: "description",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left",
      },
    },
  ];
};
export const SetOfCriteriaDepartmentColumns = (props: any) => {
  let { handleChange } = props
  return [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: '60px'
      },
      render: (row: any, index: number, numericalOrder: number) => (
        <span>{numericalOrder}</span>
      )
    },
    {
      name: "Thao tác",
      field: "action",
      headerStyle: {
        width:'60px'
      },
      render: (row: any) => {
        return (
          <div className="flex flex-space-between">
            <i className="cursor-pointer bi bi-trash fs-4 text-danger px-4 fw-bold"
              onClick={() => props.handleDelete(row?.id)}
            ></i>
          </div>
        );
      }
    },
    {
      name: "Chỉ tiêu",
      field: "name",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => {
        return (
          <TextValidator
            className="flex-row min-w-80"
            // placeholder="Tìm kiếm theo mã, tên kế hoạch"
            name="name"
            value={row?.name}
            type="text"
            onChange={(e: any) => handleChange(e.target.name, e.target.value, row.id)}
          />
        );
      }
    },
    {
      name: "Mô tả",
      field: "description",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => {
        return (
          <TextValidator
            className="flex-row min-w-80"
            // placeholder="Tìm kiếm theo mã, tên kế hoạch"
            name="description"
            value={row?.description}
            type="text"
            onChange={(e: any) => handleChange(e.target.name, e.target.value, row.id)}
          />
        );
      }
    },
  ];
};
export const FormViewSetOfCriteriaDescColumns = (props: any) => {
  return [
    {
      name: "Chỉ tiêu",
      field: "targets",
      headerStyle: {
        minWidth: "300px",
      },
      cellStyle: {
        textAlign: "start",
        padding: "0 8px"
      },
      render: (row: any) => (
        row?.radioValue === "hide" ? <h4 className='pt-2'>{row?.targets}</h4> : row?.targets
      )
    },
    {
      name: "0",
      field: "",
      headerStyle: {
        // minWidth: "200px"
      },
      render: (row: any, index: number) => (
        row?.radioValue === "hide" ? "" : <Form.Check
          type="radio"
          name={`checkbox_row_${index}`}
          value={index}
        />
      )
    },
    {
      name: "1",
      field: "",
      headerStyle: {
        // minWidth: "200px"
      },
      render: (row: any, index: number) => (
        row?.radioValue === "hide" ? "" : <Form.Check
          type="radio"
          name={`checkbox_row_${index}`}
          value={index + 1}
        />
      )
    },
    {
      name: "2",
      field: "",
      headerStyle: {
        // minWidth: "200px"
      },
      render: (row: any, index: number) => (
        row?.radioValue === "hide" ? "" : <Form.Check
          type="radio"
          name={`checkbox_row_${index}`}
          value={index + 2}
        />
      )
    },
    {
      name: "3",
      field: "",
      headerStyle: {
        // minWidth: "200px"
      },
      render: (row: any, index: number) => (
        row?.radioValue === "hide" ? "" : <Form.Check
          type="radio"
          name={`checkbox_row_${index}`}
          value={index + 3}
        />
      )
    },
    {
      name: "4",
      field: "",
      headerStyle: {
        // minWidth: "200px"
      },
      render: (row: any, index: number) => (
        row?.radioValue === "hide" ? "" : <Form.Check
          type="radio"
          name={`checkbox_row_${index}`}
          value={index + 4}
        />
      )
    },
  ];
};

export const SetOfPointLevelColumns = (props: any) => {
  const { handleChange, handleDelete, formTableAbility, errors, handleBlur } = props;
  const status = formTableAbility.table_point_ladder

  return [
    {
      name: "Tổng điểm từ",
      field: "scoreFrom",
      headerStyle: {
        minWidth: "156px",
        textAlign: "left",
      },
      cellStyle: {
        padding: "4px 7px",
      },
      render: (row: IScoreScale, index: number) => {
        return (
          <TextValidator
            className="flex-row min-w-80"
            name="scoreFrom"
            value={row?.scoreFrom}
            type="text"
            onChange={(e: any) => handleChange(e.target.name, e.target.value, index)}
            onBlur={handleBlur}
            disabled={status}
            touched={errors?.[`scoreFrom-${index}`] ? true : false}
            errors={errors?.[`scoreFrom-${index}`]}
          />
        );
      }
    },
    {
      name: "Tổng điểm đến",
      field: "scoreTo",
      headerStyle: {
        minWidth: "156px",
        textAlign: "left",
      },
      cellStyle: {
        padding: "4px 7px",
      },
      render: (row: IScoreScale, index: number) => {
        return (
          <TextValidator
            className="flex-row min-w-80"
            name="scoreTo"
            value={row?.scoreTo}
            type="text"
            onChange={(e: any) => handleChange(e.target.name, e.target.value, index)}
            disabled={status}
            onBlur={handleBlur}
            touched={errors?.[`scoreTo-${index}`] ? true : false}
            errors={errors?.[`scoreTo-${index}`]}
          />
        );
      }
    },
    {
      name: "Diễn giải",
      field: "description",
      headerStyle: {
        minWidth: "156px",
        textAlign: "left",
      },
      cellStyle: {
        padding: "4px 7px",
        textAlign: "left",
      },
      render: (row: IScoreScale, index: number) => {
        return (
          <TextValidator
            className="flex-row min-w-80"
            name="description"
            defaultValue={row?.description}
            type="text"
            onChange={(e: any) => handleChange(e.target.name, e.target.value, index)}
            onBlur={handleBlur}
            disabled={status}
          />
        );
      }
    },
    {
      name: "Điểm cộng cho người đánh giá",
      field: "scoreEmpMark",
      headerStyle: {
        minWidth: "236px",
        textAlign: "left",
      },
      cellStyle: {
        padding: "4px 7px",
      },
      render: (row: IScoreScale, index: number) => {
        return (
          <TextValidator
            className="flex-row min-w-80"
            name="scoreEmpMark"
            value={row?.scoreEmpMark}
            type="text"
            onChange={(e: any) => handleChange(e.target.name, e.target.value, index)}
            onBlur={handleBlur}
            disabled={status}
          />
        );
      }
    },
    {
      name: "Điểm cộng cho người chịu trách nhiệm",
      field: "scoreEmpOnus",
      headerStyle: {
        minWidth: "236px",
        textAlign: "left",
      },
      cellStyle: {
        padding: "4px 7px",
      },
      render: (row: IScoreScale, index: number) => {
        return (
          <TextValidator
            className="flex-row min-w-80"
            name="scoreEmpOnus"
            value={row?.scoreEmpOnus}
            type="text"
            onChange={(e: any) => handleChange(e.target.name, e.target.value, index)}
            onBlur={handleBlur}
            disabled={status}
          />
        );
      }
    },
    {
        name: "Thao tác",
        field: "khoaBaoCao",
        headerStyle: {
            display: status ? "none" : "",
            minWidth: "100px",
            textAlign: "center"
        },
        cellStyle: {
          display: status ? "none" : "",
        },
        render: (row: IScoreScale, index: number) => {
            return (
                <div className="d-flex justify-content-center">
                    <i className="cursor-pointer bi bi-trash fs-4 text-danger px-4 fw-bold"
                        onClick={() => handleDelete(row, index)}
                    />
                </div>
            )
        }
    },
  ];
}
export const SetOfPointLevelColumnsView = (props: any) => {
  return [
    {
      name: "Tổng điểm từ",
      field: "scoreFrom",
      headerStyle: {
        minWidth: "156px",
        textAlign: "left",
      },
      cellStyle: {
        padding: "4px 7px",
      },
    },
    {
      name: "Tổng điểm đến",
      field: "scoreTo",
      headerStyle: {
        minWidth: "156px",
        textAlign: "left",
      },
      cellStyle: {
        padding: "4px 7px",
      },
    },
    {
      name: "Diễn giải",
      field: "description",
      headerStyle: {
        minWidth: "156px",
        textAlign: "left",
      },
      cellStyle: {
        padding: "4px 7px",
        textAlign: "left",
      },
    },
    {
      name: "Điểm cộng cho người đánh giá",
      field: "scoreEmpMark",
      headerStyle: {
        minWidth: "236px",
        textAlign: "left",
      },
      cellStyle: {
        padding: "4px 7px",
      },
    },
    {
      name: "Điểm cộng cho người chịu trách nhiệm",
      field: "scoreEmpOnus",
      headerStyle: {
        minWidth: "236px",
        textAlign: "left",
      },
      cellStyle: {
        padding: "4px 7px",
      },
    },
  ];
}