import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { removeTenantNameFormUsername } from '../../../../app/modules/utils/FunctionUtils';

interface HeaderUserInfoModalProps {
    show: boolean;
    onClose: () => void;
    currentUser?: any;
    isJustUserInfo?: boolean;
}

const HeaderUserInfoModal: React.FC<HeaderUserInfoModalProps> = ({
    show,
    onClose,
    currentUser,
    isJustUserInfo = false
}) => {
    return (
        <Modal
            show={show}
            onHide={onClose}
            centered
            animation
            className="custom-modal category-dialog"
            size="xl"
        >
            <Modal.Header closeButton className='spaces py-15'>
                <Modal.Title>
                    <span className='spaces m-0 text-uppercase color-primary'>{`Thông tin ${!isJustUserInfo ? "tài khoản/" : ""}cá nhân`}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='spaces pt-0'>
                <div className='d-flex flex-column gap-12 spaces px-0 lh-xl'>
                    {!isJustUserInfo && <Row>
                        <Col xs={12}>
                            <div className="spaces m-0 text-uppercase color-primary fw-bold fs-16 pt-12">Thông tin tài khoản</div>
                        </Col>
                        <Col xs={4}>
                            <div className='d-flex gap-2 align-items-start'>
                                <div className='spaces min-w-100'>Tên tài khoản: </div>
                                <div className='text-lable-input color-primary'>
                                    {currentUser?.username === "null" ? '' : removeTenantNameFormUsername(currentUser?.username) }
                                </div>
                            </div>
                        </Col>
                    </Row>}

                    <Row className='spaces pt-12'>
                        {!isJustUserInfo && (
                            <Col xs={12}>
                                <div className="spaces m-0 text-uppercase color-primary fw-bold fs-16">Thông tin cá nhân</div>
                            </Col>
                        )}
                        <Col xs={3}>
                            <div className='d-flex gap-2 align-items-start'>
                                <div className='spaces min-w-90'>Mã nhân viên: </div>
                                <div className='text-lable-input color-primary'>
                                    { currentUser?.code === "null" ? '' : currentUser?.code }
                                </div>
                            </div>
                        </Col>
                        <Col xs={5}>
                            <div className='d-flex gap-2 align-items-start'>
                                <div className='spaces min-w-90'>Họ và tên: </div>
                                <div className='text-lable-input color-primary'>
                                    { currentUser?.fullName === "null" ? '' : currentUser?.fullName }
                                </div>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className='d-flex gap-2 align-items-start'>
                                <div className='spaces min-w-65'>Năm sinh: </div>
                                <div className='text-lable-input color-primary'>
                                    { currentUser?.birthYear === "null" ? '' : currentUser?.birthYear }
                                </div>
                            </div>
                        </Col>
                        <Col xs={3}>
                            <div className='d-flex gap-2 align-items-start'>
                                <div className='spaces min-w-90'>Giới tính: </div>
                                <div className='text-lable-input color-primary'>
                                    { currentUser?.gender === "null" ? '' : (currentUser?.gender === "MALE" ? "Nam" : "Nữ") }
                                </div>
                            </div>
                        </Col>
                        <Col xs={5}>
                            <div className='d-flex gap-2 align-items-start'>
                                <div className='spaces min-w-90'>Số điện thoại: </div>
                                <div className='text-lable-input color-primary'>
                                    { currentUser?.phone === "null" ? '' : currentUser?.phone }
                                </div>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className='d-flex gap-2 align-items-start'>
                                <div className='spaces min-w-65'>Email: </div>
                                <div className='text-lable-input color-primary'>
                                    { currentUser?.email === "null" ? '' : currentUser?.email }
                                </div>
                            </div>
                        </Col>
                        <Col xs={8}>
                            <div className='d-flex gap-2 align-items-start'>
                                <div className='spaces min-w-90'>Địa chỉ: </div>
                                <div className='text-lable-input color-primary'>
                                    { currentUser?.address === "null" ? '' : currentUser?.address }
                                </div>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className='d-flex gap-2 align-items-start'>
                                <div className='spaces min-w-65'>Chức vụ: </div>
                                <div className='text-lable-input color-primary'>
                                    { currentUser?.position === "null" ? '' : currentUser?.position }
                                </div>
                            </div>
                        </Col>
                        {/* <Col xs={3}>
                            <label className='spaces fs-14'>Chức vụ: </label>
                            <div className='spaces fs-14 text-muted'>
                                { currentUser?.position === "null" ? '' : currentUser?.position }
                            </div>
                        </Col>
                        <Col xs={9}>
                            <label className='spaces fs-14'>Thuộc khu vực: </label>
                            <div className='spaces fs-14 text-muted'>
                                { currentUser?.areas === "null" ? '' : currentUser?.areas }
                            </div>
                        </Col> */}
                    </Row>
                    <Col xs={12} className="justify-center spaces pt-16 mb-3 gap-10">
                        <Button
                            onClick={onClose}
                            className="spaces button-primary-outline py-4 fs-12 h-30"
                        >
                            Đóng
                        </Button>
                    </Col>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default HeaderUserInfoModal;